//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

//Icons
import { MdArrowBack } from 'react-icons/md'

const Snippet = (props) => {
  const { color, category, categorylink, gradientColor } = props

  return (
    <div
      class={`gradient-callout-${gradientColor ? gradientColor : 'tropical'}`}
    >
      <div class="container">
        <div class="row my-2 pt-2 pb-1 px-4">
          <Link
            class={`round-corners bg-${color}-1 border-1-gray-6 p-1 pl-2 pr-3 small white-1 d-flex align-items-center`}
            to={`/${categorylink}`}
          >
            <MdArrowBack class="white-1 mr-1" />
            {category}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Snippet
