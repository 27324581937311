import React, { useState } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

// import CompareItem from "../../components/marketing/compareItem"
// import IconBoat from "../../images/icon/noun_Boat_2602060.svg"
// import IconSurf from "../../images/icon/noun_Surfing_1071441.svg"
// import IconBoard from "../../images/icon/noun_Wakeboarding_1023674.svg"
// import IconSki from "../../images/icon/noun_Waterskiing_1023669.svg"

import { IoIosCheckmark } from 'react-icons/io'
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs'

import * as MaterialDesign from 'react-icons/md'
import * as Feather from 'react-icons/fi'

const Icon = (props) => {
  const { iconName, size, color } = props
  const icon = React.createElement(MaterialDesign[iconName])
  return <div style={{ fontSize: size, color: color }}>{icon}</div>
}

const Snippet = (props) => {
  const { data, gradientColor } = props
  console.log('listingTable - data')
  console.log(data)
  let allArticles = data.articles.edges
  console.log('listingTable - allArticles')
  console.log(allArticles)
  allArticles = allArticles.sort((a, b) =>
    a.node.data.sort > b.node.data.sort ? 1 : -1
  )

  console.log('listingTable - allArticles - post')
  console.log(allArticles)
  // const boatimagesfixed = data.boatimagesfixed.nodes.reverse() || []
  // console.log("[[boatimagesfixed]]")
  // console.log(boatimagesfixed)
  const emptyQuery = ''
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
    sortLengthAsc: true,
    sortSeatingAsc: true,
    showBoard: true,
    showSurf: true,
    showSki: true,
  })

  // const handleInputChange = (event, mode, prop) => {
  //   console.log("event.target.value")
  //   console.log(event.target.value)
  //   console.log("mode")
  //   console.log(mode)
  //   console.log("prop")
  //   console.log(prop)
  //   const { query } = state

  //   let newQuery = event.target.value || query
  //   const { data } = props
  //   const {
  //     filteredData,
  //     sortLengthAsc,
  //     sortSeatingAsc,
  //     showBoard,
  //     showSurf,
  //     showSki,
  //   } = state
  //   let returnObj = {}

  //   console.log("sortLengthAsc input")
  //   console.log(sortLengthAsc)
  //   console.log("sortSeatingAsc input")
  //   console.log(sortSeatingAsc)
  //   // this is how we get all of our posts
  //   const boats = data.boatreviews.edges || []
  //   // return all filtered posts
  //   let filteredDataNew = boats

  //   console.log("filteredDataNew - pre filter")
  //   console.log(filteredDataNew)
  //   if (newQuery && newQuery !== "reset") {
  //     filteredDataNew = boats.filter(boat => {
  //       // destructure data from post frontmatter
  //       const { make, model, year } = boat.node.data
  //       return make.toLowerCase().includes(newQuery.toLowerCase())
  //     })
  //   } else if (newQuery === "reset") {
  //     filteredDataNew = allReview
  //     newQuery = ""
  //   }

  //   if (prop === "board") {
  //     returnObj.showBoard = showBoard ? false : true
  //     returnObj.showSurf = showSurf
  //     returnObj.showSki = showSki
  //   } else if (prop === "surf") {
  //     returnObj.showBoard = showBoard
  //     returnObj.showSurf = showSurf ? false : true
  //     returnObj.showSki = showSki
  //   } else if (prop === "ski") {
  //     returnObj.showBoard = showBoard
  //     returnObj.showSurf = showSurf
  //     returnObj.showSki = showSki ? false : true
  //   } else {
  //     returnObj.showBoard = showBoard
  //     returnObj.showSurf = showSurf
  //     returnObj.showSki = showSki
  //   }

  //   console.log("filteredDataNew - pre cat filter")
  //   console.log(filteredDataNew)
  //   filteredDataNew = filteredDataNew.filter(boat => {
  //     // destructure data from post frontmatter
  //     const { tagsprocessed } = boat.node.fields
  //     let returnVal = false
  //     if (returnObj.showBoard && tagsprocessed.includes("board")) {
  //       returnVal = true
  //     } else if (returnObj.showSurf && tagsprocessed.includes("surf")) {
  //       returnVal = true
  //     } else if (returnObj.showSki && tagsprocessed.includes("ski")) {
  //       returnVal = true
  //     }
  //     return returnVal
  //   })
  //   console.log("filteredDataNew - post cat filter")
  //   console.log(filteredDataNew)
  //   // if (
  //   //   mode === "filter" &&
  //   //   (prop === "board" || prop === "surf" || prop === "ski")
  //   // ) {
  //   //   filteredDataNew = filteredDataNew.filter(boat => {
  //   //     // destructure data from post frontmatter
  //   //     const { tagsprocessed } = boat.node.fields
  //   //     return tagsprocessed.includes(prop)
  //   //   })
  //   // }

  //   console.log("filteredDataNew - pre sort")
  //   console.log(filteredDataNew)
  //   //default is to sort by length
  //   let sortLengthAscVal = sortLengthAsc ? 1 : -1
  //   let sortSeatingAscVal = sortSeatingAsc ? 1 : -1
  //   filteredDataNew.sort((a, b) =>
  //     a.node.data.lengthft > b.node.data.lengthft
  //       ? sortLengthAscVal
  //       : a.node.data.lengthft === b.node.data.lengthft
  //       ? a.node.data.lengthin > b.node.data.lengthin
  //         ? sortLengthAscVal
  //         : a.node.data.lengthin === b.node.data.lengthin
  //         ? a.node.data.seating > b.node.data.seating
  //           ? sortSeatingAscVal
  //           : -1 * sortSeatingAscVal
  //         : -1 * sortLengthAscVal
  //       : -1 * sortLengthAscVal
  //   )
  //   if (prop === "length") {
  //     returnObj.sortLengthAsc = sortLengthAsc ? false : true
  //     returnObj.sortSeatingAsc = sortSeatingAsc
  //   } else if (prop === "seating") {
  //     returnObj.sortLengthAsc = sortLengthAsc
  //     returnObj.sortSeatingAsc = sortSeatingAsc ? false : true
  //   } else {
  //     returnObj.sortLengthAsc = sortLengthAsc
  //     returnObj.sortSeatingAsc = sortSeatingAsc
  //   }

  //   // setState({
  //   //   sortLengthAsc: sortLengthAsc ? false : true,
  //   // })
  //   // } else if (mode === "sort" && prop === "seating") {
  //   //   let sortLengthAscVal = sortLengthAsc ? 1 : -1
  //   //   let sortSeatingAscVal = sortSeatingAsc ? 1 : -1
  //   //   filteredDataNew.sort((a, b) =>
  //   //     a.node.data.seating > b.node.data.seating
  //   //       ? sortSeatingAscVal
  //   //       : a.node.data.seating === b.node.data.seating
  //   //       ? a.node.data.lengthft > b.node.data.lengthft
  //   //         ? sortLengthAscVal
  //   //         : a.node.data.lengthft === b.node.data.lengthft
  //   //         ? a.node.data.lengthin > b.node.data.lengthin
  //   //           ? sortLengthAscVal
  //   //           : -1 * sortLengthAscVal
  //   //         : -1 * sortLengthAscVal
  //   //       : -1 * sortSeatingAscVal
  //   //   )
  //   //   returnObj.sortSeatingAsc = sortSeatingAsc ? false : true

  //   //   // setState({
  //   //   //   sortSeatingAsc: sortSeatingAsc ? false : true,
  //   //   // })

  //   console.log("filteredDataNew - post sort")
  //   console.log(filteredDataNew)
  //   // update state according to the latest query and results
  //   returnObj.query = newQuery
  //   returnObj.filteredData = filteredDataNew
  //   setState(returnObj)
  // }

  const {
    filteredData,
    query,
    sortLengthAsc,
    sortSeatingAsc,
    showBoard,
    showSurf,
    showSki,
  } = state

  // const isInitialState =
  //   query === emptyQuery && showBoard && showSurf && showSki
  const articles = allArticles
  const section = data && data.section ? data.section : {}

  // console.log("data.boatreviews.edges")
  // console.log(data.boatreviews.edges)

  // let makes = [
  //   ...new Set(data.boatreviews.edges.map(item => item.node.data.make)),
  // ]
  // makes.sort()

  // console.log("makes")
  // console.log(makes)
  // console.log("sortLengthAsc orig")
  // console.log(sortLengthAsc)
  // console.log("sortSeatingAsc orig")
  // console.log(sortSeatingAsc)

  // let sortLengthAscVal = sortLengthAsc ? 1 : -1
  // let sortSeatingAscVal = sortSeatingAsc ? 1 : -1
  // boats.sort((a, b) =>
  //   a.node.data.lengthft > b.node.data.lengthft
  //     ? sortLengthAscVal
  //     : a.node.data.lengthft === b.node.data.lengthft
  //     ? a.node.data.lengthin > b.node.data.lengthin
  //       ? sortLengthAscVal
  //       : a.node.data.lengthin === b.node.data.lengthin
  //       ? a.node.data.seating > b.node.data.seating
  //         ? sortSeatingAscVal
  //         : -1 * sortSeatingAscVal
  //       : -1 * sortLengthAscVal
  //     : -1 * sortLengthAscVal
  // )

  return (
    <div class={props.padding ? 'mx-3 my-5' : null}>
      <div class="article-container featured-container d-flex flex-row align-items-center justify-content-center row">
        {articles
          .filter((article) => article.node.data.featured === 1)
          .map((article, i) => {
            let articleTitle =
              article &&
              article.node &&
              article.node.data &&
              article.node.data.title
                ? article.node.data.title
                : ''
            let articleUrl =
              article &&
              article.node &&
              article.node.data &&
              article.node.data.title
                ? article.node.data.url
                : ''
            let articleIcon =
              article &&
              article.node &&
              article.node.data &&
              article.node.data.featuredicon
                ? article.node.data.featuredicon
                : ''

            return (
              <Link
                class={`featured-item gradient-callout-${gradientColor} round-corners border-1-${
                  section && section.data && section.data.color
                    ? section.data.color
                    : 'blue'
                }-3 p-4 m-2 text-center col align-self-stretch d-flex align-items-center justify-content-center`}
                to={`/article/${articleUrl}`}
              >
                <div
                  class="font-weight-light h5 line-height-3 white-1"
                  style={{ zIndex: 1 }}
                >
                  {articleTitle}
                </div>
                <div
                  // class="align-self-start"
                  class="featured-icon"
                >
                  {articleIcon ? (
                    <Icon iconName={articleIcon} size={65} color="#ffffff" />
                  ) : null}
                  {/* <IoIosCheckmark size={100} color="#ffffff" /> */}
                </div>
              </Link>
            )
          })}
      </div>
      <div
        class={`round-corners-top mt-4 gradient-callout-${gradientColor} white-1 p-2`}
      >
        Learn more
      </div>

      <div
        class={`container container-article-list round-corners-bottom border-1-${
          section && section.data && section.data.color
            ? section.data.color
            : 'blue'
        }-1 p-3 mb-4`}
      >
        {articles
          // .filter((article) => article.node.data.featured === 0)
          .map((article, i) => {
            let articleTitle =
              article &&
              article.node &&
              article.node.data &&
              article.node.data.title
                ? article.node.data.title
                : ''
            let articleUrl =
              article &&
              article.node &&
              article.node.data &&
              article.node.data.title
                ? article.node.data.url
                : ''
            return (
              <div class="article-list-item my-2">
                <Link
                  class="font-weight-light black-3"
                  to={`/article/${articleUrl}`}
                >
                  {articleTitle}
                </Link>
              </div>
            )
          })}
        {articles.length === 0 ? (
          <div class="row d-lg-flex justify-content-lg-center my-2 py-2 border-bottom-1-gray-6">
            <div class="col-sm-12">
              <h5 class="listing-title font-weight-light text-center gray-2 pb-2">
                No articles found
              </h5>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Snippet
